import { Box } from '@mui/material';
import { graphql } from 'gatsby';
import React from 'react';
import { LocationPageQuery } from '../../graphql-types';
import { ImageHouse } from '../assets/images';
import { HeroSection } from '../components';
import Layout from '../components/layout';

export interface LocationPageProps {
  data: LocationPageQuery;
  pageContext: any;
}

const LocationPage = (props: LocationPageProps) => {
  const { data, pageContext } = props;

  const { suburb } = pageContext;
  return (
    <Box>
      <HeroSection
        bgImage={ImageHouse}
        bgImageStyle={{
          backgroundPosition: 'bottom'
        }}
        icon={null}
        title={`Off-market ${suburb} properties you’ve never had access to `}
      />
    </Box>
  );
};

export const query = graphql`
  query LocationPage {
    allCraftGqlNewsTagsTag {
      nodes {
        title
        slug
        id
      }
    }
  }
`;

LocationPage.Layout = Layout;
export default LocationPage;
